import { Type, type Static } from '@sinclair/typebox';
import type { InjectionKey } from 'vue';
import { productSchema, type ProductSchema } from '@laam/cms-shared';

export type ProductHandlingDate = Static<typeof productHandlingDate>;
export const productHandlingDate = Type.Object({
	handling_date: Type.String(),
});

export type VariantHandlingDate = Static<typeof variantHandlingDate>;
export const variantHandlingDate = Type.Object({
	handling_date: Type.String(),
});

export type ProductVariant = ProductSchema['variants'][number];

export type ProductCarousel = Static<typeof productCarouselSchema>;

export const productCarouselSchema = Type.Union([
	Type.Array(productSchema),
	Type.Object({
		products: Type.Array(productSchema),
		count: Type.Number(),
	}),
]);
export const productInjectKey = Symbol() as InjectionKey<ProductSchema>;

export type SizeChartData = {
	[key: string]: {
		heading: string;
		data: any[]; // Update the type as per your data structure
	};
};

export type HandlingDateResponse = {
	handling_date: string;
};

export type VariantWithDate = {
	variant: string;
	shippingDate: string;
};

export const notifyBookingsSchema = Type.Object({
	msg: Type.Object({
		collection_name: Type.Union([Type.Null(), Type.String()]),
		created_at: Type.String(),
		email: Type.String(),
		id: Type.Number(),
		notification_type: Type.Union([Type.Null(), Type.String()]),
		partner_id: Type.Number(),
		phone_number: Type.Union([Type.Null(), Type.String()]),
		product_id: Type.Number(),
		product_url: Type.String(),
		product_variant_shopify_id: Type.Union([Type.Null(), Type.String()]),
		status: Type.String(),
	}),
	status_code: Type.Number(),
});

export type BookingsResponse = Static<typeof notifyBookingsSchema>;

export type OptionsMap = {
	[key: string]: {
		[variant: string]: boolean;
	};
};

export type ProductOption = {
	name: string;
	values: string[];
	position: number;
};
